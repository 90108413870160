
'use client'
import { useState } from 'react';
//import {FaEnvelopeOpenText,FaMobileScreenButton} from 'react-icons/fa6';
import {  Navbar } from 'flowbite-react';
import { Link } from "react-router-dom";
import logo from '../images/logo.png'
//import {IconContext} from "react-icons";
export default function NavbarWithCTAButton() {


  const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

  return (
<div className=' bg-inherit fixed w-full z-20 top-0 start-0'>
 {/* <nav class="bg-white border-gray-200 dark:bg-gray-900">
        <div class="flex flex-wrap grid grid-cols-2 sm:h-8 md:h-16  justify-between items-center  max-w-screen-2xl ">
            <div class=" md:flex bg-navcolour h-full items-center space-x-3 rtl:space-x-reverse  ">

                <IconContext.Provider value={{ color: 'white', size: '20px', padding:'2px 2px' }}>
                   <a href="mailto:admin@waterfieldshealthcare.com" class=" font-normal mx-auto text-white dark:text-white hover:underline">
                    <div class="flex  sm:items-center sm:justify-center text-xs md:text-base my-auto">
                    <FaEnvelopeOpenText class="sm:items-center sm:justify-center mx-auto  "/>Email: admin@waterfieldshealthcare.com
                    </div></a>
                </IconContext.Provider>

                <IconContext.Provider value={{ color: 'white', size: '20px', padding:'3px 2px' }}>
                <a href="tel:5541251234" class=" font-normal mx-auto  text-white dark:text-white hover:underline">
                    <div class="flex  sm:items-center text-xs md:text-base sm:justify-center mr-2 my-auto">
                    <FaMobileScreenButton class="sm:items-center sm:justify-center  mr-2 "/>(+44) 7451 998 627
                    </div></a>
                </IconContext.Provider>
    
            </div>
            <div class="flex items-center h-full space-x-6 rtl:space-x-reverse bg-green-400">

                <h1 className="text-lg font-bold mx-auto  text-yellowtheme1 dark:text-white">Love and Care for all</h1>
                
            </div>
        </div>
    </nav> */}

    <Navbar
      fluid
      className={
        colorChange
            ? "navbar colorChange text-lime-400"
            : "bg-inherit text-lime-400 h-4"
    }
    >
      <Navbar.Brand className='pt-0 pb-0'>
        <Link to="/"> <img alt="Flowbite React Logo" className=" logo mr-3 md:h-20 sm:h-24" src={logo} style={{width:'80px',height:'80px'}} /></Link>
        <span className={
        colorChange
            
            ? "navbar colorChange text-lime-400 self-center whitespace-nowrap  text-2xl font-extrabold"
            : " md:bg-inherit text-lime-800 self-center whitespace-nowrap  text-2xl font-extrabold"
    }>
          Step-by-Step Support 
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2 text-lime-800">
        
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse className={
        colorChange
            ? "navbar colorChange text-lime-400"
            : " bg-inherit text-lime-800"
    }>
        <Link
          active
          to="/"
        >
          <p className=' font-bold md:text-lg hover:bg-icyteal hover:text-crystalteal'>
            Home
          </p>
        </Link>
        <Link to="/about" className=' font-bold md:text-lg hover:bg-icyteal hover:text-crystalteal'>
          About
        </Link>
        <Link to="/activities" className=' font-bold md:text-lg hover:bg-icyteal hover:text-crystalteal'>
         Activities
        </Link>
        {/* <div className=' font-bold md:text-lg'>
          <Dropdown
          inline
          label="Domiciliary"
          className=' font-bold md:text-lg bg-navcolour hover:bg-icyteal hover:text-crystalteal'

        >
        <Dropdown.Header className={
        colorChange
            ? "navbar colorChange text-lime-400"
            : " bg-inherit text-lime-800"
    }>
        <ul class="py-2 text-sm  dark:text-gray-400">
        <Link to="/social" className=' font-bold md:text-sm hover:bg-blue-700 hover:text-white'>
          Social Inclusion
        </Link><br/>
        <Link to="/personal" className=' font-bold md:text-sm hover:bg-blue-700 hover:text-white'>
          Personal Care
        </Link><br/>
        <Link to="/sitting" className=' font-bold md:text-sm hover:bg-blue-700 hover:text-white'>
          Sitting Call
        </Link><br/>
                </ul>
          </Dropdown.Header>
          
        </Dropdown> </div>*/}
        <Link to="/services" className=' font-bold md:text-lg hover:bg-icyteal hover:text-crystalteal'>
          Services
        </Link>
        <Link to="/careers" className=' font-bold md:text-lg hover:bg-icyteal hover:text-crystalteal'>
          Careers
        </Link>
        
        <Link to="/contact" className=' font-bold md:text-lg hover:bg-icyteal hover:text-crystalteal'>
          Contact
        </Link>
      </Navbar.Collapse>
    </Navbar>
    </div>
  )
}
