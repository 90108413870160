import React from 'react'
//import { Link } from 'react-router-dom'
import garden from '../images/2151462944.jpg'
import garden1 from '../images/garden.jpg'
import learning from '../images/2149060879.jpg'
import learning2 from '../images/2147664205.jpg'
import fun from '../images/2151205679.jpg'
import hike from '../images/42682.jpg'
import çommunity from '../images/2151346564.jpg'
function Activities() {
  return (
    <div className='relative w-full bg-cover bg-center bg-no-repeat   bg-blend-multiply mb-5'>
        {/* <Helmet>
            <title>Domiciliary Care</title>
            <meta name="description" content="See our detailed domiciliary care services"/>
            <link rel="canonical" href="/domiciliary" />
        </Helmet> */}
        <section >
        <section class=" bg-cover bg-center bg-no-repeat bg-activities bg-gray-100  bg-blend-multiply">
            <div class="px-4 mx-auto max-w-screen-xl   py-24 lg:py-40">
                <h1 class="mb-4 text-2xl font-extrabold  tracking-tight leading-none text-darkbluetheme md:text-3xl lg:text-4xl">
                Activity-Based Care:</h1>
               <div className='md:flex'>
                    <div class="  md:w-1/2 pt-4  rounded-lg  text-darkbluetheme mx-4 ">
                                

                                <p class=' font-semibold text-sm md:text-lg px-2'>
                            We offer a range of activities:<br/><br/>
                                <div className='ml-6'><ul class="list-disc ">
                                <li><a class='' href='#gardening'><u>Gardening</u></a></li>
                                <li><a href='#learning'><u>Learning</u></a> </li> 
                                <li><a href='#others'><u>Other activities</u></a></li>
                                
                                </ul></div>
                                
                                <br/><br/>

                                </p>
                                <div class=" col-span-1 px-2 pt-1 pb-2">
                            <img src={çommunity}class=" rounded-lg md:h-60  mr-3" alt="gardening"/> 
                        </div>
                                

                    </div>

                    <div class="  md:w-1/2  border-1 border-navcolour sm:82 rounded-lg   text-darkbluetheme   mx-4 ">
                            <h5 class="mx-auto mt-2 mb-4 text-1xl font-bold tracking-tight leading-none  text-darkbluetheme md:text-2xl lg:text-2xl">
                            What is Activity-Based Care? <br/><br/>
                            </h5>

                            <p class='font-normal text-justify text-sm md:text-lg px-2'>
                            Under social inclusion, STEP-BY-STEP uses Activity-Based Care healthcare delivery model that emphasises preventive care, client 
                            engagement and health promotion. It is based on the premise that by keeping people active and engaged in their health, they will 
                            be more likely to stay healthy and avoid costly medical interventions. There is a growing body of evidence that Activity-Based Care (ABC) 
                            can have a positive impact on the health and well-being of older adults. ABC is an approach to care that emphasizes promoting and 
                            supporting physical activity and social engagement.  We work to engage individuals we support in their own health and wellness 
                            through education, counselling, and community support.<br/><br/>
                        
                            <br/>
                            </p>

                        </div> 
   
                </div> 


                <div>

                <p class='font-normal text-darkbluetheme text-sm md:text-base px-2'>
                    There are many potential benefits of Activity Based Care, including:<br/><br/>

                            <ul class="list-disc ml-6">
                            <li><b>Improved physical health</b></li>
                                <p class='font-normal text-darkbluetheme text-sm md:text-base px-2 pb-3'>
                                    Regular physical activity can help reduce the risk of many chronic diseases, such as heart 
                                    disease, stroke, type II diabetes, and some forms of cancer. It can also help improve mental health, 
                                    sleep quality, and overall quality of life.
                                </p>
                            <li><b>Improved cognitive health</b></li>
                                <p class='font-normal text-darkbluetheme text-sm md:text-base px-2 pb-3'>
                                Engaging in social activities and stimulating mental activities can help reduce the risk of cognitive decline and dementia.

                                </p>
                            <li><b>Increased independence and quality of life</b></li>
                                <p class='font-normal text-darkbluetheme text-sm md:text-base px-2 pb-3'>
                                People who are physically active and engaged in social activities are more likely to maintain their independence and have a higher quality of life.
                                </p>


                            <li><b>Reduced hospitalizations and emergency room visits.</b></li>
                                <p class='font-normal text-darkbluetheme text-sm md:text-base px-2 pb-3'>
                                Regular physical activity can help reduce the number of hospitalizations and emergency room visits.
                                </p>
                            </ul>
                        
                            <br/><br/>
                            </p>

                </div>
               
            </div>
        </section>
            <div class=" px-4 mx-auto max-w-screen-xl t pt-4">

                <div class=" mx-auto max-w-screen-xl text-center pt-6  ">
                    <h3 class=" mb-4 text-2xl font-extrabold tracking-tight leading-none text-emerald-800 md:text-3xl lg:text-3xl">
                       Our Activities:
                    </h3>
                </div> 

                <div class=" mt-4 ">

                <h4 class=" mt-4 mb-4 text-xl font-extrabold tracking-tight leading-none text-emerald-800 md:text-3xl lg:text-2xl">
                       Gardening:
                    </h4>
                <div id='gardening' class="md:flex mt-4 pt-8 pb-4">
                        <div class=" col-span-1 px-2 pt-4">
                            <img src={garden}class=" rounded-lg md:h-96  mr-3" alt="gardening"/> 
                        </div>

                        <div class=" col-span-1 px-2 pt-2">
                            <img src={garden1}class=" rounded-lg md:h-96  mr-3" alt="gardening"/> 
                        </div>
                       

                    </div>
                    
               

                <p class='font-normal bg-bluetheme text-darkbluetheme text-sm md:text-base   sm:items-center sm:justify-center px-2'>
                The benefits of gardening are well documented and we offer a full range of horticultural experiences to service users. Whether its 
                potting and planting, harvesting our allotment or maintaining our wildlife-friendly wildflower garden service users can experience 
                the therapeutic benefits of being close to nature. Spending time outdoors and tending to plants is known to reduce stress and promote 
                a sense of accomplishment and higher self-esteem, as well as boosting psychological well-being.
                </p>

                <p class='font-normal bg-bluetheme text-darkbluetheme text-sm md:text-base   sm:items-center sm:justify-center px-2'>
                <br/>Our garden team work in our 3 garden allotments, providing the following services to individuals we support:  <br/><br/>
                    <span className='px-1 md:px-5 flex'>
                        <ul class="list-disc ml-2 md:ml-8 " >
                            <li>General garden maintenance</li>
                            <li>Grass cutting</li>
                            <li>Weed control</li>
                            <li>Hedge & shrub trimming</li>
                            <li>Strimming & border maintenance</li>
   
                        </ul> 

                        <ul class="list-disc px-2 md:px-4 ml-8 " >
                            <li>Leaf blowing & collection</li>
                            <li>Moss removal</li>
                            <li>Branch collection</li>
                            <li>Bespoke repairs</li>
   
                        </ul> 
                    </span>
                    
                    <br/>
                    To learn more, please contact our garden supervisor on  0740 0180829 or email us at <a className='underline text-navcolour' href="mailto:info@stepbystepsuppport.co.uk">info@stepbystepsuppport.co.uk</a> . 
                </p>
                </div>


                <div class=" pt-8 ">

                <h4 class="bg-gradient-to-r from-violet-950 to-fuchsia-950 bg-clip-text text-  mb-4 text-xl font-extrabold tracking-tight leading-none text-emerald-800 md:text-3xl lg:text-2xl">
                Social Inclusion and Learning:
                    </h4>
                <div id='learning' class="md:flex pt-4 pb-4">
                        <div class=" col-span-1 px-2 pt-2">
                            <img src={learning}class=" rounded-lg md:h-96  mr-3" alt="gardening"/> 
                        </div>

                        <div class=" col-span-1 px-2 pt-2">
                            <img src={learning2}class=" rounded-lg md:h-96  mr-3" alt="gardening"/> 
                        </div>
                       

                    </div>
                    
               

                <p class='font-normal bg-bluetheme text-darkbluetheme text-sm md:text-base   sm:items-center sm:justify-center px-2'>
                Our Companionship and Social Inclusion services is designed to provide friendly and engaging support inside the home and in the 
                community. We help individuals maintain their independence and quality of life.<br/><br/>

                Our caring professionals are trained to provide personalised care that is tailored to meet the unique needs and preferences of each 
                individual client. We work closely with clients and their families to ensure that they receive the highest level of care and support.<br/><br/>

                STEP-BY-STEP utilises teaching and learning resources provided by WEA, Adult Learning Within Reach in Leicester. We support individuals to get 
                membership and attend face to face or online activities depending on their preference. With WEA, our service users can access learning activities 
                like skills for work, skills for life, English for Speakers of Other Languages (ESOL), Arts and crafts, language and culture, humanities and science.
                </p>

               
                </div>


                <div class=" pt-7 ">

                <h4 class=" mb-4 text-xl font-extrabold tracking-tight leading-none text-darkbluetheme md:text-3xl lg:text-2xl">
                Other Activities and Community Engagement:
                    </h4>
                <div id='others' class="md:flex pt-4 pb-4">
                        <div class=" col-span-1 px-2 pt-2">
                            <img src={hike}class=" rounded-lg md:h-96  mr-3" alt="gardening"/> 
                        </div>

                        <div class=" col-span-1 px-2 pt-2">
                            <img src={fun}class=" rounded-lg md:h-96  mr-3" alt="gardening"/> 
                        </div>
                       

                    </div>
                    
               

                <p class='font-normal bg-bluetheme text-darkbluetheme text-sm md:text-base   sm:items-center sm:justify-center px-2'>
                Our experienced staff will work closely with you to develop different packages of activities. We work hard to identify skills and areas of 
                development, making sure everyone who attends our activities has a fulfilling experience. These will be meaningful, stimulating, enjoyable 
                whilst enabling independence as well as personal growth through identified learning needs. We develop activities and learning modules based 
                on feedback from our customers according to what you enjoy doing.<br/><br/>

                Our activity programme includes sensory based activities, this is relaxing and calming for customers with complex needs. We have interactive 
                computers for those who wish to enhance their IT skills and we have a fully integrated and accessible garden allotments. We also support various 
                activities in the local community such as;<br/><br/>
                </p>

                <p class='font-normal bg-bluetheme text-darkbluetheme text-sm md:text-base   sm:items-center sm:justify-center px-2'>
                <br/>
                    <span className='px-1 md:px-5 flex'>
                        <ul class="list-disc ml-2 md:ml-8 " >
                            <li>Gardening projects - we have 3 allotments in Leicester</li>
                            <li>Summer Coach trips</li>
                            <li>Summer camping and hiking</li>
                            <li>Summer walks along water canals</li>
                            <li>Cycling trips</li>
                            <li>Sporting activities</li>

   
                        </ul> 

                        <ul class="list-disc px-4 ml-8 " >
                            <li>Sunday visits to car boots collection</li>
                            <li>Fishing trips</li>
                            <li>Holiday trips to seaside and excursions to museums</li>
                            <li>Group gatherings for friendships and association</li>
                            <li>Life skills education</li>

   
                        </ul> 
                    </span>
                    
                    
                </p>
                </div>
               
            </div>
        </section>
    </div>
  )
}

export default Activities